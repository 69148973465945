import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import { useWindowSize } from 'react-use'

import { Seo } from '@atoms'
import { FollowUs, Storytime, HowItWorks } from '@molecules'
import { Layout, Homegrown, NovelEffect, Hero } from '@organisms'

export const HomeView = () => {
  const data = useStaticQuery(homeQuery)
  const seoImage = getSrc(data.metaImage)
  const followImages = [data.follow1, data.follow2, data.follow3, data.follow4]

  const windowSize = useWindowSize()
  const backgroundHeight = windowSize.width * 0.65 * 0.5625
  const heightToggle =
    windowSize.height <
    windowSize.width * 0.6 * 0.5625 + windowSize.height * 0.4
      ? true
      : false

  const mobileFlip =
    windowSize.height < 500 && windowSize.width < 900 ? true : false
  const width = heightToggle ? 'calc( 65vh * 1.777777777 ) ' : '80%'
  const height = heightToggle ? '65vh' : ' calc( (100vw * .5625 ) * .8)'
  const button = heightToggle ? 50 : 100
  const mobileWidth = mobileFlip ? 'auto' : '100%'
  const mobileHeight = mobileFlip ? '60vh' : 'calc( 100vw * .5625 )'

  return (
    <Layout>
      <Seo title="Annies&#8482;" metaImage={seoImage} />
      <Hero
        width={width}
        image={data.videoPlaceholder}
        modalHeight={height}
        button={button}
        mobileHeight={mobileHeight}
        mobileWidth={mobileWidth}
        backgroundHeight={backgroundHeight}
      />
      <HowItWorks image={data.howItWorks} />
      <NovelEffect
        novelEffect1={data.novelEffect1}
        novelEffect2={data.novelEffect2}
      />
      <Storytime image={data.storytelling} />
      <Homegrown />
      <FollowUs images={followImages} />
    </Layout>
  )
}
const homeQuery = graphql`
  query {
    metaImage: file(relativePath: { eq: "annies-novel-effect.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    follow1: file(relativePath: { eq: "social-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    follow2: file(relativePath: { eq: "social-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    novelEffect2: file(relativePath: { eq: "family-cooking.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    follow3: file(relativePath: { eq: "social-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    novelEffect1: file(relativePath: { eq: "dad-at-table.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
    }
    follow4: file(relativePath: { eq: "social-04.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    storytelling: file(relativePath: { eq: "kids-at-counter.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
    howItWorks: file(relativePath: { eq: "novel-effect-hippo.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    videoPlaceholder: file(relativePath: { eq: "videoPlaceholder.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 50)
      }
    }
  }
`
